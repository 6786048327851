import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppConstants } from '../utils/app-constants';
import { ToastrMessages } from '../utils/toastr-messages';
import { AlertMessageService } from '../services/alerts/alert-message.service';
import { LoaderService } from '../services/loader.service';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class AppInterceptor implements HttpInterceptor {
    // HTTP related variables
    requestCount: number;
    private errorMessage = ToastrMessages.HTTP_ERROR_MESSAGES.DEFAULT;

    // Error related Variables
    errorMsg: string;

    constructor(
        private router: Router,
        private loaderService: LoaderService,
        private alertMessageService: AlertMessageService,
    ) {
        this.requestCount = 0;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.requestCount++;
        return next.handle(req).pipe(
            map(
                (event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        this.decrementRequestCount();
                        return event;
                    }
                },
            ),
            catchError((error: HttpErrorResponse) => {
                this.showCustomErrorMessages(error);
                this.decrementRequestCount();
                return throwError(error);
            }),
        );
    }
    private decrementRequestCount(): void {
        this.requestCount--;
        if (this.requestCount <= 0) {
            this.loaderService.stopLoaders();
        }
    }

    /**
     * Author: T0553
     * Description: Displays  custom alert messages based on error code
     */
    private showCustomErrorMessages(errorCode): void {
        switch (errorCode.status) {
            case AppConstants.ERROR_CODES.ERROR_400:
                this.errorMessage = ToastrMessages.HTTP_ERROR_MESSAGES.ERROR_BAD_REQUEST_400;
                break;
            case AppConstants.ERROR_CODES.ERROR_401:
                // this.handleInvalidToken(errorCode);
                this.errorMessage = ToastrMessages.HTTP_ERROR_MESSAGES.ERROR_AUTHENTICATION_ERROR_401;
                break;
            case AppConstants.ERROR_CODES.ERROR_404:
                this.errorMessage = ToastrMessages.HTTP_ERROR_MESSAGES.ERROR_NOT_FOUND_404;
                break;
            case AppConstants.ERROR_CODES.ERROR_429:
                this.errorMessage = ToastrMessages.HTTP_ERROR_MESSAGES.ERROR_AUTHENTICATION_ERROR_429;
                break;
            case AppConstants.ERROR_CODES.ERROR_503:
                this.errorMessage = ToastrMessages.HTTP_ERROR_MESSAGES.ERROR_SERVICE_ERROR_503;
                break;
            case AppConstants.ERROR_CODES.ERROR_504:
                this.errorMessage = ToastrMessages.HTTP_ERROR_MESSAGES.ERROR_GATEWAY_ERROR_504;
                break;
            case AppConstants.ERROR_CODES.ERROR_401:
            // this.handleAuthenticationError(errorCode.status);
        }
        this.alertMessageService.showErrorToster(this.errorMessage);

    }


    /**
     * Author: T0553
     * Description: Displays  Error messages form Error Code
     */
    private showErrorMessages(errorData): void {
        // Handles error based on code and inside statusCode
        switch (errorData && errorData.status) {
            case AppConstants.ERROR_CODES.ERROR_0:
                this.errorMessage = errorData.message;
                break;
            case AppConstants.ERROR_CODES.ERROR_400:
                // Displays a common error message since the error is unknown from the server side
                // this.handleInvalidToken(errorData);
                this.errorMessage = errorData.message;
                break;
            case AppConstants.ERROR_CODES.ERROR_401:
                // Displays a common error message since the error is unknown from the server side
                // this.openAlert();
                break;
            case AppConstants.ERROR_CODES.ERROR_500:
                // Displays a common error message since the error is unknown from the server side
                this.errorMessage = errorData.message;
                break;
        }
        if (errorData.status !== AppConstants.ERROR_CODES.ERROR_401) {
            this.alertMessageService.showErrorToster(this.errorMessage);
        }
    }

    /**
     * Author: T0553
     * Description: If user is unauthenticated redirect user to login page by default
     */
    // private handleAuthenticationError(errorCode): void {
    //     if (errorCode === AppConstants.ERROR_CODES.ERROR_401) {
    //         this.authService.logout();
    //     }
    // }

    /**
     * Description: handles invalid token or session expiry
     */

    // private handleInvalidToken(error) {
    //     if (error.status === AppConstants.ERROR_CODES.ERROR_401 ) {
    //         this.storageService.clearStorage();
    //         this.router.navigateByUrl(AppConstants.REDIRECT_URLS.login);
    //     }
    // }
    // private openAlert(){
    //     Swal.fire({
    //         title: 'Authentication Failed',
    //         text: 'User authentication failed',
    //         confirmButtonText: 'OKAY',
    //       })
    // }
}
