export class ThirdPartyLibraryConfig {

/**
 * Author: T0553
 * Description: Configuration of toastr
 */
public static toastrConfig = {
    maxOpened: 1,
    timeout: 5000,
    closeButton: true,
    autoDismiss: true,
    progressBar: true,
    preventDuplicates: true,
    positionClass: 'toast-top-right',
};

}
