export class AppConstants {


    /**
     * Author: T0553
     * Description: Stores the keywords used to store values in memory
     */
    public static LOCAL_STORAGE_VARIABLES = {

    };

    public static get TIMEZONE(): any {
        return {
            CST: 'America/Chicago'
        };
    }

    /**
     * Author: TO516
     * Description: Stores redirect urls
     */
    public static REDIRECT_URLS = {

    };
    // Error codes
    public static get ERROR_CODES(): any {
        return {
            ERROR_0: 200,
            ERROR_2: 2,
            ERROR_SUCCESS_1: 201,
            ERROR_204: 204,

            ERROR_400: 400,
            ERROR_401: 401,
            ERROR_404: 404,
            ERROR_429: 429,

            ERROR_500: 500,
            ERROR_503: 503,
            ERROR_504: 504,
            ERROR_FAILURE_0: 0,
        };
    }
    public static get TIMEZONE_LIST(): any {
        return [
            {
                timeZone:'America/New_York',
                label: 'EST',//EST
                value: 'US',
            },
            {
                timeZone:'America/Chicago',
                label: 'CST',//CST
                value: 'US',
            },
            {
                timeZone:'America/Los_Angeles',
                label: 'PST',//PST
                value: 'US',
            },
            // {
            //     label: 'America/Los_Angeles',
            //     value: 'US',
            // },
            // {
            //     label: 'America/Chihuahua',
            //     value: 'US',
            // },
            // {
            //     label: 'America/Creston',
            //     value: 'US',
            // },
            // {
            //     label: 'America/Dawson',
            //     value: 'US',
            // },
            // {
            //     label: 'America/Dawson_Creek',
            //     value: 'US',
            // },
            // {
            //     label: 'America/Denver',
            //     value: 'US',
            // },
            // {
            //     label: 'America/Detroit',
            //     value: 'US',
            // },
            // {
            //     label: 'America/Fort_Nelson',
            //     value: 'US',
            // },
            // {
            //     label: 'America/El_Salvador',
            //     value: 'US',
            // },
            // {
            //     label: 'America/Goose_Bay',
            //     value: 'US',
            // },
            // {
            //     label: 'America/Grenada',
            //     value: 'US',
            // },
            // {
            //     label: 'America/Guadeloupe',
            //     value: 'US',
            // },
            // {
            //     label: 'America/Guatemala',
            //     value: 'US',
            // },
            // {
            //     label: 'America/Halifax',
            //     value: 'US',
            // },
            // {
            //     label: 'America/Havana',
            //     value: 'US',
            // },
            // {
            //     label: 'America/Indiana/Indianapolis',
            //     value: 'US/Indianapolis',
            // },
            // {
            //     label: 'America/Indiana/Knox',
            //     value: 'US/Knox',
            // },
            // {
            //     label: 'America/Indiana/Marengo',
            //     value: 'US/Marengo',
            // },
            // {
            //     label: 'America/Indiana/Marengo',
            //     value: 'US/Marengo',
            // },
            // {
            //     label: 'America/Indiana/Tell_City',
            //     value: 'US/Tell_City',
            // },
            // {
            //     label: 'America/Indiana/Vevay',
            //     value: 'US/Vevay',
            // },
            // {
            //     label: 'America/Indiana/Vincennes',
            //     value: 'US/Vincennes',
            // },
            // {
            //     label: 'America/Indiana/Winamac',
            //     value: 'US/Winamac',
            // },
            // {
            //     label: 'America/Inuvik',
            //     value: 'US',
            // },
            // {
            //     label: 'America/Juneau',
            //     value: 'US',
            // },
            // {
            //     label: 'America/Kentucky/Louisville',
            //     value: 'US/Louisville',
            // },
            // {
            //     label: 'America/Kentucky/Monticello',
            //     value: 'US/Monticello',
            // },
            // {
            //     label: 'America/Menominee',
            //     value: 'US',
            // },
            // {
            //     label: 'America/Merida',
            //     value: 'US',
            // },
            // {
            //     label: 'America/Metlakatla',
            //     value: 'US',
            // },
            // {
            //     label: 'America/Nome',
            //     value: 'US',
            // },
            // {
            //     label: 'America/North_Dakota/Beulah',
            //     value: 'US/Beulah',
            // },
            // {
            //     label: 'America/North_Dakota/Center',
            //     value: 'US/Center',
            // },
            // {
            //     label: 'America/North_Dakota/Center',
            //     value: 'US/Center',
            // },
            // {
            //     label: 'America/North_Dakota/New_Salem',
            //     value: 'US/New_Salem',
            // },
            // {
            //     label: 'America/Phoenix',
            //     value: 'US',
            // },
            // {
            //     label: 'America/Puerto_Rico',
            //     value: 'US',
            // },
            // {
            //     label: 'America/Santiago',
            //     value: 'US',
            // },
            // {
            //     label: 'America/Sao_Paulo',
            //     value: 'US',
            // },
            // {
            //     label: 'America/Sitka',
            //     value: 'US',
            // },
            // {
            //     label: 'America/St_Johns',
            //     value: 'US',
            // },
            // {
            //     label: 'America/St_Kitts',
            //     value: 'US',
            // },
            // {
            //     label: 'America/St_Lucia',
            //     value: 'US',
            // },
            // {
            //     label: 'America/St_Thomas',
            //     value: 'US',
            // },
            // {
            //     label: 'America/St_Vincent',
            //     value: 'US',
            // },
            // {
            //     label: 'America/Thule',
            //     value: 'US',
            // },
            // {
            //     label: 'America/Tijuana',
            //     value: 'US',
            // },
            // {
            //     label: 'America/Toronto',
            //     value: 'US',
            // },
            // {
            //     label: 'America/Yakutat',
            //     value: 'US',
            // },
            // {
            //     label: 'America/Yellowknife',
            //     value: 'US',
            // }
        ];
    }
    public static get MSG_HEADER(): any {
        return {
            UNENCRYPTED: 1,
            ENCRYPTED: 2,
        };
    }
    // public static get RANDON_CONSENT_FIELDS(): any {
    //     return [
    //         {
    //             label: 'MR ID',
    //             key: 'mr_id',
    //         },
    //         {
    //             label: 'Date Of Birth',
    //             key: 'dob',
    //         }
    //     ];
    // }
    public static get RANDON_CONSENT_FIELDS(): any {
        return [           
            {
                label: 'Date Of Birth',
                key: 'dob',
            }
        ];
    }

    public static get SPINNER_CONFIGS(): any {
        return {
            size: 'default',
            type: 'ball-spin-clockwise',
            text: 'Please Wait...'
        };
    }

    public static get MESSAGE(): any {
        return {
            ERROR_MESSAGE: {
                VIDEO_URL_EMPTY: 'Room does not exist.'
            },
            SUCCESS_MESSAGE: {},
        };
    }
    public static get AGE_MAX(): any {
        return {
            VALUE: 100,
        };
    }
}
