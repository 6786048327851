import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AlertMessageService {

  alertMsg: string;
  msgTitle: string;

  constructor(
    private toastr: ToastrService
  ) {
    this.alertMsg = '';
    this.msgTitle = '';
  }

  /**
   *  Author: T0553
   * Description: Shows an error alert message
   */
  public showErrorToster(message: string, title?: string): void {
    this.toastr.error(message);
  }

  /**
   * Author: T0553
   * Description: Shows a warning alert message
   */
  public showWarningToster(message: string, title?: string): void {
    this.toastr.warning(message);
  }

  /**
   * Author: T0553
   * Description: Shows a success alert message
   */
  public showSuccessToster(message: string, title?: string): void {
    this.toastr.success(message);
  }
}
