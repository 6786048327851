import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor(
    private SpinnerService: NgxSpinnerService
  ) { }

  public startLoaders(): void {
    this.SpinnerService.show();
  }

  public stopLoaders(): void {
    this.SpinnerService.hide();
  }

}
