import { EventEmitter, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { AppConstants } from './app-constants';
import * as moment from 'moment-timezone';
@Injectable()

export class CommonService {

  location: EventEmitter<any> = new EventEmitter();
  currentDate: EventEmitter<any> = new EventEmitter();
  hospitalTimezone: any;
  constructor() {
  }

  public isEmpty(obj): boolean {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  public getCurrentDateTime(): any {
    return moment().tz(this.hospitalTimezone).format('DD MMM YYYY');
  }

  public setCurrentDateTime(): void {
    if (this.hospitalTimezone) {
      this.currentDate.emit(moment().tz(this.hospitalTimezone).format('DD MMM YYYY'));
    }
  }
  public setHeaderLocation(hospitalName): void {
    this.location.emit(hospitalName);
  }


}
