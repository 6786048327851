import { Component } from '@angular/core';
import { AppConstants } from './core/utils/app-constants';
import { Router, NavigationStart, NavigationEnd, NavigationCancel } from '@angular/router';
import { LoaderService } from './core/services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'patient-portal';
  public spinnerSize: string;
  public spinnerType: string;
  public spinnerText: string;
  constructor(
    private router: Router,
    private loaderService: LoaderService) {
    this.spinnerSize = AppConstants.SPINNER_CONFIGS.size;
    this.spinnerType = AppConstants.SPINNER_CONFIGS.type;
    this.spinnerText = AppConstants.SPINNER_CONFIGS.text;
    this.manageLoaderDisplay();
  }
  public manageLoaderDisplay(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loaderService.startLoaders();
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        setTimeout(() => {
          this.loaderService.stopLoaders();
        }, 1000);
      }
    });
  }
}
